<template>
	<div class="index-container">
		<el-row :gutter="20">
			<!-- 资源概况 -->
			<el-col :span="24">
				<el-card>
					<el-row :gutter="20" type="flex">
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #6777EF;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.user_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">人</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									用户总数
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #409EFF;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.order_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">单</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									订单总数
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #67C23A;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.today_user_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">人</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									今日新增人数
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #51E3C0;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.today_order_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">单</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									今日新增订单
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="20" type="flex" class="mt-2">
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #50D1FC;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.goods_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">个</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									商品总数
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #E6A23C;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.tocash_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">笔</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									今日提现
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #EE82EE;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.fh_order_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">单</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									待发货
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #FC544B;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.tk_order_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">单</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									待退款
								</div>
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>

		<el-row :gutter="20" class="mt-3">
			<!-- 会员概况 -->
			<el-col :span="12">
				<el-card :body-style="{ padding: '20px' }">
					<div class="w-100 flex align-center mb-2 fs_20" style="height: 45px;">
						<span class="left-flag"></span>会员概况
					</div>
					<div class="w-100 flex-all">
						<div ref="chart1" style="width: 100%; height: 200px;"></div>
					</div>
				</el-card>
			</el-col>
			<!-- 订单概况 -->
			<el-col :span="12">
				<el-card :body-style="{ padding: '20px' }">
					<div class="w-100 flex align-center mb-2 fs_20" style="height: 45px;">
						<span class="left-flag"></span>订单概况
					</div>
					<div class="w-100 flex-all">
						<div ref="chart2" style="width: 100%; height: 200px;"></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		
		<el-row :gutter="20" class="mt-3">
			<!-- 会员概况 -->
			<el-col :span="12">
				<el-card :body-style="{ padding: '20px' }">
					<div class="w-100 flex align-center mb-2 fs_20" style="height: 45px;">
						<span class="left-flag"></span>会员数量
					</div>
					<div class="w-100 flex-all">
						<div ref="chart3" style="width: 100%; height: 200px;"></div>
					</div>
				</el-card>
			</el-col>
			<!-- 订单概况 -->
			<el-col :span="12">
				<el-card :body-style="{ padding: '20px' }">
					<div class="w-100 flex align-center mb-2 fs_20" style="height: 45px;">
						<span class="left-flag"></span>订单数量
					</div>
					<div class="w-100 flex-all">
						<div ref="chart4" style="width: 100%; height: 200px;"></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import * as echarts from "echarts"
	export default {
		name: 'Index',
		data () {
			return {
                information:{},
				system_log:[],
				chart1: {
				  tooltip: {
					trigger: 'item',
					formatter: "{c}人"
				  },
				  legend: {
					orient: 'vertical',
					left: 'left'
				  },
				  series: [
					{
					  type: 'pie',
					  radius: ['65%', '90%'],
					  left: 20,
					  label: {
						  show: false,
						  position: 'center'
					  },
					  data: [],
					  emphasis: {
						label: {
						  show: true,
						  fontSize: 22,
						  fontWeight: 'bold',
						  formatter: "{c}人\n{b}"
						}
					  }
					}
				  ]
				},
				chart2: {
				  tooltip: {
					trigger: 'item',
					formatter: "{c}个"
				  },
				  legend: {
					orient: 'vertical',
					left: 'left'
				  },
				  series: [
					{
					  type: 'pie',
					  radius: ['65%', '90%'],
					  left: 20,
					  label: {
						  show: false,
						  position: 'center'
					  },
					  data: [],
					  emphasis: {
						label: {
						  show: true,
						  fontSize: 22,
						  fontWeight: 'bold',
						  formatter: "{c}个\n{b}"
						}
					  }
					}
				  ]
				},
				chart3: {
					title: {},
					tooltip : {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					legend: {
						data:['新增人数']
					},
					grid: {
						left: '2%',
						right: '2%',
						bottom: '0%',
						containLabel: true
					},
					xAxis : [
						{
							type : 'category',
							boundaryGap : false,
							data : []
						}
					],
					yAxis : [
						{
							type : 'value'
						}
					],
					series : [
						{
							name:'新增人数',
							type:'line',
							stack: '总量',
							label: {
								normal: {
									show: true,
									position: 'top'
								}
							},
							areaStyle: {normal: {}},
							data:[120, 132, 101, 134, 90, 230, 210]
						},
					]
				},
				chart4: {
					title: {},
					tooltip : {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					legend: {
						data:['下单数','退款数']
					},
					grid: {
						left: '2%',
						right: '2%',
						bottom: '0%',
						containLabel: true
					},
					xAxis : [
						{
							type : 'category',
							boundaryGap : false,
							data : []
						}
					],
					yAxis : [
						{
							type : 'value'
						}
					],
					series : [
						{
							name:'下单数',
							type:'line',
							stack: '总量',
							label: {
								normal: {
									show: true,
									position: 'top'
								}
							},
							areaStyle: {normal: {}},
							data:[120, 132, 101, 134, 90, 230, 210],
							areaStyle: {
								  color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [  // 渐变颜色
									  {
										offset: 0,
										color: '#1DFDFD',
									  },
									  {
										offset: 1,
										color: '#FFFFFF',
									  },
									],
									global: false,
								  },
							},
							lineStyle: {
								width: 3,
								normal: {
								  color: '#1DFDFD', // 折线线条颜色
								},
							}
						},
						{
							name:'退款数',
							type:'line',
							stack: '总量',
							label: {
								normal: {
									show: true,
									position: 'top'
								}
							},
							areaStyle: {normal: {}},
							data:[120, 132, 101, 134, 90, 230, 210],
							areaStyle: {
								  color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [  // 渐变颜色
									  {
										offset: 0,
										color: '#6777EF',
									  },
									  {
										offset: 1,
										color: '#FFFFFF',
									  },
									],
									global: false,
								  },
							},
							lineStyle: {
								width: 3,
								normal: {
								  color: '#6777EF', // 折线线条颜色
								},
							}
						},
					]
				},
			}
		},
		mounted(){
			this.getInformation()
            this.userRatio()
			this.orderRatio()
			this.userStatistics()
			this.orderStatistics()
		},
		methods:{
            getInformation(){
			   this.axios.get('/manage/information').then(res=>{
				 this.information = res.data
			   })
            },
			userRatio(){
				this.axios.get('/manage/user_ratio').then(res=>{
					if(res.status){
						if(res.data.length > 0){
							let data = []
							if(res.data.length > 0){
								res.data.forEach((v)=>{
									data.push({ value: v.nums, name: v.name })
								})
							}
							this.chart1.series[0].data = data
						}
					}
					this.drawLine1()
				})
			},
			orderRatio(){
				this.axios.get('/manage/order_ratio').then(res=>{
					if(res.status){
						if(res.data.length > 0){
							let data = []
							if(res.data.length > 0){
								res.data.forEach((v)=>{
									data.push({ value: v.nums, name: v.name })
								})
							}
							this.chart2.series[0].data = data
						}
					}
					this.drawLine2()
				})
			},
			userStatistics(){
				this.axios.get('/manage/user_statistics').then(res=>{
					if(res.status){
						let result = res.data
						this.chart3.legend.data = result.legend.data
						this.chart3.xAxis[0].data = result.xAxis[0].data
						this.chart3.series[0].name = result.series[0].name
						this.chart3.series[0].data = result.series[0].data
						// 画统计图
						this.$nextTick(()=>{
							let myChart = echarts.init(this.$refs.chart3)
							myChart.setOption(this.chart3)
						})
					}
				})
			},
			orderStatistics(){
				this.axios.get('/manage/order_statistics').then(res=>{
					if(res.status){
						let result = res.data
						this.chart4.legend.data = result.legend.data
						this.chart4.xAxis[0].data = result.xAxis[0].data
						this.chart4.series[0].name = result.series[0].name
						this.chart4.series[0].data = result.series[0].data
						this.chart4.series[1].name = result.series[1].name
						this.chart4.series[1].data = result.series[1].data
						// 画统计图
						this.$nextTick(()=>{
							let myChart = echarts.init(this.$refs.chart4)
							myChart.setOption(this.chart4)
						})
					}
				})
			},
			drawLine1() {
				 // 初始化echarts实例
				 let myChart = echarts.init(this.$refs.chart1)
				 // 配置参数
				 myChart.setOption(this.chart1)
				 //默认选中第一项
				var index = 0
				myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});//设置默认选中高亮部分
				//鼠标移入事件      
				myChart.on('mouseover',function(e){	
					if(e.dataIndex != index){
						myChart.dispatchAction({type: 'downplay', seriesIndex: 0, dataIndex: index  });
					}		
				});
				//鼠标移出事件
				myChart.on('mouseout',function(e){		
				   index = e.dataIndex;
				   myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: e.dataIndex});	
				});
			},
			drawLine2() {
				 // 初始化echarts实例
				 let myChart = echarts.init(this.$refs.chart2)
				 // 配置参数
				 myChart.setOption(this.chart2)
				 //默认选中第一项
				var index = 0
				myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});//设置默认选中高亮部分
				//鼠标移入事件      
				myChart.on('mouseover',function(e){	
					if(e.dataIndex != index){
						myChart.dispatchAction({type: 'downplay', seriesIndex: 0, dataIndex: index  });
					}		
				});
				//鼠标移出事件
				myChart.on('mouseout',function(e){		
				   index = e.dataIndex;
				   myChart.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: e.dataIndex});	
				});
			},
		},
}
</script>

<style>
.left-flag{
	width: 8px;
	height: 25px;
	background: #018EED;
	margin-right: 10px;
}
</style>
